// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyD1b854e94JWud0Y4gJnQoYUOyRs1l_Hus",
    authDomain: "monkbuidler.firebaseapp.com",
    projectId: "monkbuidler",
    storageBucket: "monkbuidler.appspot.com",
    messagingSenderId: "579220468630",
    appId: "1:579220468630:web:2152e37a74da4a78a9e3b7",
    measurementId: "G-KKXC76H8D7"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
