import React, { useEffect } from 'react';
import { WalletProvider, ConnectionProvider, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import '@solana/wallet-adapter-react-ui/styles.css';
import { db } from '../firebaseConfig'; // Make sure this is correctly set up in your project
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';

// Define your wallet adapters
const wallets = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
];

const WalletConnectProvider = ({ children }) => {
    const endpoint = clusterApiUrl('devnet');

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    {children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export const ConnectWalletButton = () => {
    const wallet = useWallet();

    useEffect(() => {
        const logWalletConnection = async () => {
            if (wallet.connected && wallet.publicKey) {
                const walletAddress = wallet.publicKey.toString();
                const walletDocRef = doc(db, "walletConnections", walletAddress);

                const docSnap = await getDoc(walletDocRef);
                if (docSnap.exists()) {
                    // Document exists, update count and timestamp
                    await updateDoc(walletDocRef, {
                        count: docSnap.data().count + 1,
                        lastConnected: serverTimestamp(),
                    });
                } else {
                    // Document does not exist, create it
                    await setDoc(walletDocRef, {
                        walletAddress: walletAddress,
                        count: 1,
                        lastConnected: serverTimestamp(),
                    });
                }
            }
        };

        logWalletConnection();
    }, [wallet.connected, wallet.publicKey]);

    return (
        <WalletMultiButton 
            style={{
                marginTop: '20px',
                marginBottom: '20px',
                marginRight: '20px',
                padding: '20px',
                position: 'fixed',
                top: '0',
                right: '0',
                color: 'white',
                backgroundColor: '#9945FF',
                borderRadius: '10px',
                zIndex: '1000',
                cursor: 'pointer',
                fontFamily: 'Micro 5, sans-serif',
            }}
        />
    );
};

export { WalletConnectProvider };
