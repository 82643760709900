import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import React Router for navigation
import styled, { keyframes } from 'styled-components';
import { ConnectWalletButton } from './ConnectWalletButton';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #ededed;
    min-height: 100vh;
    padding-bottom: 40px;
    font-family: "Micro 5", sans-serif;
`

const Heading = styled.h1`
    font-size: 3em;
    font-weight: 400;
    padding: 20px;
    margin:0;
    margin-bottom: 20px;
    padding-top: 100px;
`

const GalleryDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-bottom: 60px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;

    // background-color: #f1f1f1;

    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    `

    const Img = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 25px;

    &:hover {
        transform: scale(1.05);
        transition: transform 0.3s;
    }

    @media (max-width: 768px) {
        width: 100px;
        height: 100px;
    }

    @media (max-width: 1080px) {
        width: 150px;
        height: 150px;
    }

    @media (max-width: 500px) {
        width: 100px;
        height: 100px;
    }

    @media (max-width: 350px) {
        width: 60px;
        height: 60px;
    }
    `

    // const Subheading = styled.h2`
    // font-size: 2em;
    // font-weight: 400;
    // margin: 0;
    // margin-bottom: 40px;
    // margin-top: -20px;
    // `


    const PrevNextButton = styled.button`
    background-color: #f1f1f1;
    border: none;
    color: black;
    margin: 0 5px;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Micro 5", sans-serif;
    font-size: 1.5em;

    &:hover {
        background-color: #ddd;
    }

    &:disabled {
        background-color: #f1f1f1;
        color: #666;
    }

    @media (max-width: 768px) {
        padding: 4px 8px;
    }
    `

    const SearchInput = styled.input`
    background-color: #f1f1f1;
    // border: none;
    border: 2.5px solid black;
    color: black;
    margin: 0 5px;
    padding: 8px 16px;
    text-align: right;
    text-decoration: none;
    display: inline-block;
    border-radius: 3px;
    cursor: pointer;
    font-family: "Micro 5", sans-serif;
    font-size: 1.5em;
    min-width: 250px;
    `

    const SearchButton = styled.button`
    background-color: #f1f1f1;
    border: 2.5px solid black;
    color: black;
    margin: 0 5px;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;

    border-radius: 5px;
    cursor: pointer;
    font-family: "Micro 5", sans-serif;
    font-size: 1.5em;

    &:hover {
        background-color: black;
        color: white;
    }

    `;

    const PageNumberButton = styled.button`
    background-color: #f1f1f1;
    border: none;
    color: black;
    margin: 0 5px;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Micro 5", sans-serif;
    font-size: 1.5em;

    &:hover {
        background-color: #ddd;
    }

    &.active {
        background-color: #4CAF50;
        color: white;
    }

    &:disabled {
        background-color: #f1f1f1;
        color: #666;
    }

    @media (max-width: 768px) {
        padding: 4px 8px;
    }
    `

    const Name = styled.div`
    text-align: center;
    text-decoration: none;
    color: black;
    font-size: 1.75em;
    // text-transform: uppercase;
    margin-top: 10px;

    @media (max-width: 768px) {
        font-size: 1.1em;
    }
    `

    const LinkStyled = styled(Link)`
    text-decoration: none;
    align-items: center;
    justify-content: center;
    // background-color: blue;
    display: flex;
    flex-direction: column;
    `


    const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

    // Additional styles for LoadingIndicator
    const LoadingIndicator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    font-size: 24px;
    gap: 20px;
    
    // Add a simple before pseudo-element to represent the loader
    &:before {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      border: 4px solid #ccc;
      border-top-color: #000; // This part will be the "loader"
      border-radius: 50%;
      animation: ${rotate} 2s linear infinite;
    }
  `;

    const SearchForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    `







const Gallery = ({ images, totalImages = 9420 }) => {

    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const imagesPerPage = 10;

    // Calculate the index of the last and first image on the current page
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;

    // Slice the current images to display based on pagination
    const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

    // Function to change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (!images || images.length === 0) {
        return (
            <Container>
                <ConnectWalletButton />
                <Heading>Monk Builders</Heading>
                <LoadingIndicator> Buidl Loading...</LoadingIndicator>
            </Container>
        );
    }

    return (
        <Container>
            <ConnectWalletButton />
            <Heading>Monk Buidlers</Heading>
            <SearchForm>
            <SearchInput placeholder="Search for a Monk (0-9420)" />
            <SearchButton
                onClick = {() => {

                    // Validate the input value
                    const input = document.querySelector('input').value;
                    if (isNaN(input) || input < 0 || input > 9420 || input === null || input === "") {
                        alert('Please enter a valid Monk number between 0 and 9420');
                        return;
                    }

                    // Navigate to the image page with the image name

                    navigate(`/monk/${document.querySelector('input').value}`);
                }}
            >Search</SearchButton>
            </SearchForm>
            <GalleryDiv className="gallery">
                {currentImages.map((image, index) => (
                    <LinkStyled key={index} to={`/monk/${image.name}`}>
                        {/* Using the /assets folder path assuming the images are stored in public/assets */}
                        <Img src={image.ipfsLink} alt={`Monk Builder #${image.name}`} loading="lazy"/>
                        <Name>
                            {image.description}
                        </Name>
                    </LinkStyled>
                ))}
            </GalleryDiv>
            <div className="pagination">
                {/* Previous Button */}
                <PrevNextButton disabled={currentPage === 1} onClick={() => paginate(currentPage - 1)}>Prev</PrevNextButton>

                {/* Always show the first page */}
                <PageNumberButton className={currentPage === 1 ? 'active' : ''} onClick={() => paginate(1)}>1</PageNumberButton>

                {/* Show ellipsis if there are pages between the first page and the current page - 2 */}
                {currentPage > 4 && <span>...</span>}

                {/* Show up to 2 pages before the current page */}
                {currentPage > 3 && <PageNumberButton onClick={() => paginate(currentPage - 2)}>{currentPage - 2}</PageNumberButton>}
                {currentPage > 2 && <PageNumberButton onClick={() => paginate(currentPage - 1)}>{currentPage - 1}</PageNumberButton>}

                {/* Current Page */}
                {currentPage > 1 && currentPage < totalImages / imagesPerPage && (
                    <PageNumberButton className="active">{currentPage}</PageNumberButton>
                )}

                {/* Show up to 2 pages after the current page */}
                {currentPage < (totalImages / imagesPerPage) - 1 && <PageNumberButton onClick={() => paginate(currentPage + 1)}>{currentPage + 1}</PageNumberButton>}
                {currentPage < (totalImages / imagesPerPage) - 2 && <PageNumberButton onClick={() => paginate(currentPage + 2)}>{currentPage + 2}</PageNumberButton>}

                {/* Show ellipsis if there are pages between the current page + 2 and the last page */}
                {(totalImages / imagesPerPage) - currentPage > 3 && <span>...</span>}

                {/* Always show the last page */}
                {currentPage !== Math.ceil(totalImages / imagesPerPage) && (
                    <PageNumberButton onClick={() => paginate(Math.ceil(totalImages / imagesPerPage))}>{Math.ceil(totalImages / imagesPerPage)}</PageNumberButton>
                )}

                {/* Next Button */}
                <PrevNextButton disabled={currentPage === Math.ceil(totalImages / imagesPerPage)} onClick={() => paginate(currentPage + 1)}>Next</PrevNextButton>
            </div>

        </Container>
    );
};

export default Gallery;
