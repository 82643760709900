  // In App.js
  import React, { useEffect, useState } from 'react';
  import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
  import { WalletConnectProvider } from './components/ConnectWalletButton';
  import Gallery from './components/Gallery';
  import ImagePage from './components/ImagePage';
  import { db } from './firebaseConfig'; // Import db from firebaseConfig
  import { collection, getDocs } from 'firebase/firestore';

  const App = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
      const fetchImages = async () => {

        const imagesCollection = collection(db, 'BirthMetadata');

        const imageSnapshot = await getDocs(imagesCollection);

        console.log("imagesList");
        const imagesList = imageSnapshot.docs.map(doc => doc.data());
        setImages(imagesList);
        console.log("imagesList");
      };

      fetchImages();
    }, []);

    return (
      <WalletConnectProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Gallery images={images} />} />
            <Route path="/monk/:imageName" element={<ImagePage images={images} />} />
          </Routes>
        </Router>
      </WalletConnectProvider>
    );
  };

  export default App;
