
// ImagePage.js
import { ConnectWalletButton } from './ConnectWalletButton';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, clusterApiUrl } from '@solana/web3.js';
import { actions } from '@metaplex/js';
import { PublicKey } from '@solana/web3.js';
import { db } from '../firebaseConfig';
import { collection, addDoc } from "firebase/firestore"; 
import { Metaplex, walletAdapterIdentity, toMetaplexFileFromBrowser } from '@metaplex-foundation/js';
// import { CandyMachine } from '../CandyMachine'; // Make sure to implement CandyMachine logic as discussed

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    justify-content: center;
    background-color: #ededed;
    padding:20px;
    font-family: "Micro 5", sans-serif;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`;

const Img = styled.img`
    width: 500px;
    height: 500px;
    border-radius: 25px;

    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const DescriptionDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-left: 20px;
`;

const Name = styled.h2`
    margin-bottom: 10px;
    font-size: 3em;
    text-align: left;
    font-weight: 400;

    @media (max-width: 768px) {
        font-size: 2em;
    }

`;

const ColorAttribute = styled.p`
    margin: 5px;
    text-align: left;
    font-size: 1.8em;
    width: 100%;

`

const BackToGallery = styled.button`
    background-color: #f1f1f1;
    border: none;
    color: black;
    margin: 0 5px;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Micro 5", sans-serif;
    font-size: 1.75em;

    &:hover {
        background-color: #ddd;
    }
`;

const MintButton = styled.button`
    background-color: #4CAF50;
    border: none;

    color: white;
    // margin: 0 5px;
    margin-top: 20px;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: "Micro 5", sans-serif;
    font-size: 1.75em;

    &:hover {
        background-color: #45a049;
    }

    &:disabled {
        background-color: #f1f1f1;
        color: #666;
    }

    @media (max-width: 768px) {
        padding: 4px 8px;
    }
`;

const ImagePage = ({ images }) => {
    const { imageName } = useParams();
    const image = images.find(img => img.name === imageName);
    const wallet = useWallet();
    const [candyMachine, setCandyMachine] = useState(null);


    // const initCandyMachine = async () => {
    //     if (!wallet.connected || !candyMachineId) return;

    //     const connection = new Connection(clusterApiUrl('devnet'));
    //     const cm = await CandyMachine.create({
    //         connection,
    //         wallet: wallet.adapter, // Ensure your wallet adapter is compatible
    //         candyMachineId,
    //     });
    //     setCandyMachine(cm);
    // };


    // const connection = new Connection(clusterApiUrl('devnet'));
    // const metaplex = Metaplex.make(connection).use(walletAdapterIdentity(wallet));

    // useEffect(() => {
    //     const endpoint = clusterApiUrl('devnet');
    //     const connection = new Connection(endpoint);
    //     const metaplex = Metaplex.make(connection).use(walletAdapterIdentity(wallet));
    // }, [connection, wallet]);

    // const connect = useState(new Connection(clusterApiUrl('devnet')));
    // const metaplex = useState(Metaplex.make(connect).use(walletAdapterIdentity(wallet)));



    // const mintNFT = async () => {
    //     if (!wallet.connected || !image) return;

    //     // // Establish connection

    //     // useEffect(() => {
    //     // const metaplex = Metaplex.make(connection).use(walletAdapterIdentity(wallet)); 
    //     // }, [connection, wallet]);

    //     // useEffect(() => {
    //     //     const metaplex = Metaplex.make(connection).use(walletAdapterIdentity(wallet));
    //     // }, [connection, wallet]);


    //     console.log("Connection and Metaplex context established.");

    //     const metadataUri = image.metadataURI;
    //     // Handling of metadataUri remains unchanged...

    //     try {
    //         // Upload your metadata to a decentralized storage if not already an IPFS link or similar
    //         // For this example, we assume the metadataUri is directly usable
    //         // Metadata should follow the Metaplex token metadata standard

    //         // Mint the NFT
    //         console.log('Minting NFT...');
    //         console.log('Metadata URI:', metadataUri);
    //         console.log('Name:', image.description);

    //         // const { nft } = await metaplex.nfts().create({
    //         //     uri: metadataUri,
    //         //     name: image.description, // or another field that you prefer
    //         //     sellerFeeBasisPoints: 500, // 5% royalty. Adjust as needed.
    //         // }).run();

    //         // console.log('Minted NFT:', nft);

    //         // Update your Firebase or other database records as needed
    //         // const snapshot = await db.collection('BirthMetadata').where('name', '==', imageName).get();
    //         // if (!snapshot.empty) {
    //         //     const docId = snapshot.docs[0].id;
    //         //     await db.collection('BirthMetadata').doc(docId).update({
    //         //         minted: true,
    //         //         mintAddress: nft.mint.address.toString(),
    //         //     });
    //         // } else {
    //         //     console.log('No document matches the provided name');
    //         // }
    //     } catch (error) {
    //         console.error('Error minting NFT:', error);
    //     }
    // };

    const mintNFT = async () => {
        // if (!wallet.connected || !image || !candyMachine) {
        //     console.log('Wallet not connected or image/candy machine not found.');
        //     return;
        // }

        // const metadataUri = image.metadataURI; // Using the IPFS URI from your image object
        // if (!metadataUri) {
        //     console.log('No metadata URI found for the image.');
        //     return;
        // }

        // try {
        //     console.log('Minting NFT...');
        //     console.log('Metadata URI:', metadataUri);
        //     // Ensure your CandyMachine class handles the mint process, including SOL transfer
        //     await candyMachine.mint(); // Modified to pass metadataUri if needed
        //     console.log('NFT minted successfully.');
        // } catch (error) {
        //     console.error('Error minting NFT:', error);
        // }
        alert("Hey! Your wayyy to early. Mint starts on March 10th, 2024. Stay tuned for more updates.");

        //  log the interaction in a new firestore collection called MintAttempt and log the users wallet address, the image name, and the timestamp of the attempt.

        try {
            const mintAttempt = {
                walletAddress: wallet.publicKey.toString(),
                imageName: image.description,
                timestamp: new Date().toISOString(),
            };
    
            // Add mintAttempt to "MintAttempt" collection
            const docRef = await addDoc(collection(db, "MintAttempt"), mintAttempt);
            console.log("Mint attempt logged to Firestore:", docRef.id);
        } catch (error) {
            console.error("Error logging mint attempt to Firestore:", error);
        }

    };

    if (!image) {
        return <div>Image not found</div>;
    }

    return (
        <Container>
            <ConnectWalletButton />
            <BackToGallery onClick={() => window.history.back()}>Back to Gallery</BackToGallery>
            <Wrapper>
                <Img src={image.ipfsLink} alt={`Monk Builder #${image.name}`} />
                <DescriptionDiv>
                    <Name>{image.description}</Name>
                    <ColorAttribute>Background Color: {image.background}</ColorAttribute>
                    <ColorAttribute>Foreground Color: {image.foreground}</ColorAttribute>
                    <MintButton onClick={mintNFT}>Mint Now</MintButton>
                </DescriptionDiv>
            </Wrapper>
        </Container>
    );
};


export default ImagePage;
